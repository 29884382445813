import { useFormikContext } from 'formik';
import CountrySelector from './CountrySelector';
import RegionSelector from './RegionSelector';
import VatField from './VatField';
import { useCountries } from 'hooks/useCountries';
import { useRegions } from 'hooks/useRegions';
import { FormikBillingValues } from 'models/Billing';

const CountryRegionVatSelector = () => {
  const { countries } = useCountries();
  const { values } = useFormikContext<FormikBillingValues>();
  if (values.country == null || values.country === '') values.country = 'US';
  const { regions } = useRegions(values.country);

  return (
    <>
      <CountrySelector countries={countries} />
      <RegionSelector regions={regions} />
      <VatField country={values.country} />
    </>
  );
};

export default CountryRegionVatSelector;
