import { useState, useEffect } from 'react';
import { useCybersource } from 'hooks/useCybersource';
import { useCybersourceContext } from 'contexts/CybersourceContext';
import ProgressStepper from 'components/ProgressStepper';
import { Formik, FormikHelpers } from 'formik';
import { merge } from 'lodash';
import {
  UBSBillingPayload,
  UBSFormikBillingValues,
  CybersourcePayerAuthSetup,
  UBSFormikInitialTermsOnlyValues,
  FormStatus,
  TermsOnlyPayload,
  CybersourceLogging,
} from 'models/Billing';
import {
  UBSInitialValues,
  UBSValidationSchema,
  UBSSelfServeValidationSchema,
  PageContainer,
  termsOnlyValidationSchema,
  initialTermsOnlyValues,
} from './SignupPaymentPage';
import CybersourceBillingWrapper from 'components/CybersourceBillingWrapper';
import cybersourceErrors from 'pages/shared/CybersourcePaymentPageErrors';
import { useBillingInfo } from 'hooks/useBillingInfo';
import { UBSBillingInfo } from 'models/BillingInfo';
import { CybersourcePayerAuthResponse } from 'models/Billing';
import { useTransactionDetails } from 'hooks/useTransactionDetails';
import TermsCopyContainer from 'components/TermsCopyContainer';
import PayerAuthIframe from 'components/PayerAuthIframe';
import parseJwt from 'utils/jwtUtils';
import { useTransaction } from 'hooks/useTransaction';
import setSegmentCookie, { currencyStringToNumber } from 'utils/CybersourceSetSegmentCookie';

const CybersourcePaymentPage = ({ portalConfirmationPageUrl }: { portalConfirmationPageUrl: string }) => {
  const { sendBillingInfo, setupPayerAuthStep1, submitPayerAuthIframe, logging } = useCybersource();
  const { billingInfo } = useBillingInfo();
  const { microform } = useCybersourceContext();
  const [billingPayload, setBillingPayload] = useState<UBSBillingPayload>();
  const [payerAuthSetupPayload, setPayerAuthSetupPayload] = useState<CybersourcePayerAuthSetup>();
  const [payerAuthSetupResponse, setPayerAuthSetupResponse] = useState<CybersourcePayerAuthResponse>();
  const [tokenHook, setToken] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { transactionDetails } = useTransactionDetails();
  const { transaction } = useTransaction();
  const isEsmSelfServe = transactionDetails?.signup_code?.includes('esm_self_serve');
  const accountDetailsAreNotPrepopulated = !(transactionDetails?.account_details_prepopulated === true);
  const isPendingSalesOrderWithoutCc = transactionDetails?.signup_code === 'pending_sales_order_without_cc';

  useEffect(() => {
    if (billingInfo) {
      merge(UBSInitialValues, { email: (billingInfo as UBSBillingInfo)?.email || '' });
    }
  }, [billingInfo]);

  useEffect(() => {
    if (payerAuthSetupResponse) {
      submitPayerAuthIframe();
    }
  }, [payerAuthSetupResponse, submitPayerAuthIframe]);

  // Display terms & conditions if needed
  if (isPendingSalesOrderWithoutCc) {
    return (
      <PageContainer>
        {/* Should ProgressStepper be removed? Or changed for T&C? */}
        <ProgressStepper />
        <Formik
          initialValues={initialTermsOnlyValues}
          validationSchema={termsOnlyValidationSchema}
          onSubmit={async (
            values: UBSFormikInitialTermsOnlyValues,
            helpers: FormikHelpers<UBSFormikInitialTermsOnlyValues>,
          ) => {
            const payload: TermsOnlyPayload = {};
            helpers.setStatus(FormStatus.Submitting);
            setErrorMessage('');
            sendBillingInfo(payload, {
              onSuccess: () => {
                setSegmentCookie(transaction, transactionDetails);
                // Go to Portal confirmation page
                window.location.href = `${portalConfirmationPageUrl}?email=${encodeURIComponent(
                  transactionDetails.email || '',
                )}&transaction_id=${transactionDetails.id}`;
              },
              onError: (error: any) => {
                setErrorMessage('An error occurred while attempting to accept the Terms of Service.');
              },
            });
          }}
        >
          <TermsCopyContainer errorMessage={errorMessage} />
        </Formik>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <ProgressStepper />
      <Formik
        initialValues={UBSInitialValues}
        validationSchema={
          isEsmSelfServe && accountDetailsAreNotPrepopulated ? UBSSelfServeValidationSchema : UBSValidationSchema
        }
        onSubmit={async (values: UBSFormikBillingValues, helpers: FormikHelpers<UBSFormikBillingValues>) => {
          // Use formik state to set global form status
          const loggingPayload: CybersourceLogging = {
            transactionId: transactionDetails.id,
            logLevel: 'info',
            message: `Pay Button Clicked, UA: ${window.navigator.userAgent}`,
          };
          logging(loggingPayload);
          helpers.setStatus(FormStatus.Submitting);
          setErrorMessage('');
          let currentYear = new Date().getFullYear();

          if (!values.creditCardExpiryDate) {
            helpers.setStatus(FormStatus.Error);
            helpers.setFieldError('creditCardExpiryDate', cybersourceErrors.expiryDate);
            return;
          }

          const options = {
            expirationMonth: values.creditCardExpiryDate?.split('/')[0].trim(),
            expirationYear: values.creditCardExpiryDate?.split('/')[1]?.trim()
              ? currentYear.toString().slice(0, -2) + values.creditCardExpiryDate?.split('/')[1]?.trim()
              : undefined,
          };
          const loggingTokenPayload: CybersourceLogging = {
            transactionId: transactionDetails.id,
            logLevel: 'info',
            message: 'Calling token creation',
          };
          logging(loggingTokenPayload);
          await microform?.createToken(options, (err: any, token: string) => {
            if (err) {
              helpers.setStatus(FormStatus.Error);
              const hasCardNumberError = err?.details.some((e: any) => e?.location === 'number');
              const hasCvcError = err?.details.some((e: any) => e?.location === 'securityCode');
              if (hasCardNumberError) {
                helpers.setFieldError('creditCard', cybersourceErrors.cardNumber);
              } else if (hasCvcError) {
                helpers.setFieldError('creditCard', cybersourceErrors.securityCode);
              } else if (hasCardNumberError && hasCvcError) {
                helpers.setFieldError('creditCard', cybersourceErrors.cardNumberAndSecurityCode);
              } else {
                helpers.setFieldError('creditCard', cybersourceErrors.default);
              }
              if (err?.reason === 'CREATE_TOKEN_VALIDATION_SERVERSIDE') {
                setErrorMessage('Session expired! Please reload the page.');
              } else {
                setErrorMessage('Unable to Tokenize Credit Card, Please try again');
              }
              const loggingPayload: CybersourceLogging = {
                transactionId: transactionDetails.id,
                logLevel: 'error',
                message: 'Unable to Tokenize Credit Card',
                error: `Message: ${err?.message}, Reason: ${err?.reason}`,
              };
              logging(loggingPayload);
              return;
            }
            const loggingPayload: CybersourceLogging = {
              transactionId: transactionDetails.id,
              logLevel: 'info',
              message: 'Card Token Created Successfully',
            };
            logging(loggingPayload);
            if (token) {
              const payload: UBSBillingPayload = {
                address1: values.billingAddress,
                address2: values.billingAddress2,
                city: values.city,
                state: values.region,
                country: values.country,
                zip: values.zip,
                transientTokenJwt: token,
                vatId: values.vatId,
                firstName: values.firstName,
                lastName: values.lastName,
                cardType: values.cardType[0]?.name,
                email: values.email,
                accountFirstName: values.accountFirstName,
                accountLastName: values.accountLastName,
                company: values.company,
                phoneNumber: values.phoneNumber,
                accountEmail: values.accountEmail || transactionDetails.email,
                currency: transaction.currency,
                totalAmount: currencyStringToNumber(transaction.cart_summary.total).toString(),
              };
              setToken(token);
              setBillingPayload(payload);
              const tokenData = parseJwt(token);
              const payerAuthSetupPayload: CybersourcePayerAuthSetup = {
                clientReferenceInformation: { code: transactionDetails.id },
                tokenInformation: { transientToken: tokenData?.jti },
                card: {
                  type: tokenData?.data?.type,
                  expirationMonth: tokenData?.data?.expirationMonth,
                  expirationYear: tokenData?.data?.expirationYear,
                  number: tokenData?.content?.paymentInformation?.card?.number?.bin,
                },
              };
              setPayerAuthSetupPayload(payerAuthSetupPayload);

              const loggingPayload: CybersourceLogging = {
                transactionId: transactionDetails.id,
                logLevel: 'info',
                message: 'Calling Payer Auth Step 1',
              };
              logging(loggingPayload);

              setupPayerAuthStep1(payerAuthSetupPayload, {
                onSuccess: (response) => {
                  //Set up Iframe
                  setPayerAuthSetupResponse(response);
                },
                onError: (error: any) => {
                  setErrorMessage('An error occurred while setting up Payer Authentication');
                  helpers.setStatus(FormStatus.Error);
                  const loggingPayload: CybersourceLogging = {
                    transactionId: transactionDetails.id,
                    logLevel: 'error',
                    message: 'Calling Payer Auth Step 1',
                    error: error,
                  };
                  logging(loggingPayload);
                },
              });
            } else {
              setErrorMessage('Unable to Tokenize Credit Card, Please try again');
              helpers.setStatus(FormStatus.Error);
              const loggingPayload: CybersourceLogging = {
                transactionId: transactionDetails.id,
                logLevel: 'error',
                message: 'No token received',
              };
              logging(loggingPayload);
            }
          });
        }}
      >
        <>
          <PayerAuthIframe
            deviceDataCollectionURL={payerAuthSetupResponse?.consumerAuthenticationInformation.deviceDataCollectionUrl}
            accessToken={payerAuthSetupResponse?.consumerAuthenticationInformation.accessToken}
            payerAuthSetupPayload={payerAuthSetupPayload}
            payerAuthSetupResponse={payerAuthSetupResponse}
            billingPayload={billingPayload}
            tokenHook={tokenHook}
            portalConfirmationPageUrl={portalConfirmationPageUrl}
            setErrorMessage={setErrorMessage}
            isUpdateFlow={false}
          />
          <CybersourceBillingWrapper errorMessage={errorMessage} />
        </>
      </Formik>
    </PageContainer>
  );
};

export default CybersourcePaymentPage;
