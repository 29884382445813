import { createContext, ReactElement, useContext, useEffect, useState } from 'react';
import { StripeClientSecretRequest } from 'models/Billing';
import { useUBSStripe } from 'hooks/useStripe';
import { loadStripe, Stripe } from '@stripe/stripe-js';

export const InitialContextValues = {
  clientSecret: '',
  stripePromise: null,
};

export interface StripeContextProps {
  clientSecret: string;
  stripePromise: PromiseLike<Stripe | null> | null;
}

export const StripeContext = createContext<StripeContextProps>(InitialContextValues);

const StripeProvider = ({ children }: { children: ReactElement }) => {
  const { getClientSecret } = useUBSStripe();
  const [clientSecret, setclientSecretHook] = useState('');
  const [stripePromise, setStripePromise] = useState<PromiseLike<Stripe | null> | null>(null);
  const stripePayload: StripeClientSecretRequest = {
    stripeId: '',
  };

  useEffect(() => {
    getClientSecret(stripePayload, {
      onSuccess: (response) => {
        setclientSecretHook(response?.clientSecret);
      },
    });
  }, []);
  useEffect(() => {
    if (typeof process.env.REACT_APP_STRIPE_PK === 'string') {
      const promise = loadStripe(String(process.env.REACT_APP_STRIPE_PK));
      setStripePromise(promise);
    }
  }, []);

  return (
    <StripeContext.Provider
      value={{
        clientSecret,
        stripePromise,
      }}
    >
      {children}
    </StripeContext.Provider>
  );
};

const useStripeContext = () => {
  const context = useContext(StripeContext);
  if (context === undefined) {
    throw new Error('useStripeContext must be used within a StripeProvider');
  }
  return context;
};

export { StripeProvider, useStripeContext };
