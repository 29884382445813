import { useEffect, useState } from 'react';
import {
  UBSBillingPayload,
  UBSFormikBillingValues,
  UBSFormikInitialBillingValues,
  CybersourcePayerAuthResponse,
  CybersourcePayerAuthSetup,
  FormStatus,
} from 'models/Billing';
import * as Yup from 'yup';
import { ZipRegex } from 'utils/validationUtils';
import { Container, Typography } from '@material-ui/core';
import { Formik, FormikHelpers } from 'formik';
import cybersourceErrors from 'pages/shared/CybersourcePaymentPageErrors';
import { useCybersource } from 'hooks/useCybersource';
import { useCybersourceContext } from 'contexts/CybersourceContext';
import CybersourceBillingWrapper from 'components/CybersourceBillingWrapper';
import PayerAuthIframe from 'components/PayerAuthIframe';
import parseJwt from 'utils/jwtUtils';
import { useTransactionDetails } from 'hooks/useTransactionDetails';

const validationSchema = Yup.object({
  firstName: Yup.string().required(`First name is required`),
  lastName: Yup.string().required(`Last name is required`),
  email: Yup.string().email('Please enter a valid email').required('Email is required'),
  billingAddress: Yup.string().required(`Billing address is required`),
  region: Yup.string().required(`State / Region is required`),
  city: Yup.string().required(`City is required`),
  zip: Yup.string().when('country', {
    is: 'US',
    then: Yup.string()
      .matches(ZipRegex, `Zip / Postal code must be 5 digits`)
      .required(`Zip / Postal code is required`),
    otherwise: Yup.string().required(`Zip / Postal code is required`),
  }),
});

const StripeUBSUpdatePaymentPage = ({ portalInvoicePageUrl }: { portalInvoicePageUrl: string }) => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { transactionDetails } = useTransactionDetails();

  return (
    <Container maxWidth="md">
      <Typography>This is UBS Update CC Page</Typography>
    </Container>
  );
};

export default StripeUBSUpdatePaymentPage;
