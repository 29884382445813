import { useEffect, useState } from 'react';
import { Typography, Box, styled, Link, FormHelperText } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import FormFieldLabel from 'components/BillingForm/FormFieldLabel';
import { FormStatus } from 'models/Billing';
import { useTransactionDetails } from 'hooks/useTransactionDetails';

const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
});

const StyledCheckbox = styled('input')({
  marginRight: '10px',
});

const ValidationErrorText = styled(FormHelperText)({
  marginLeft: '26px',
});

const TermsCheckbox = () => {
  const [termsStartDate, setTermsStartDate] = useState<string | null>(null);
  const [termsEndDate, setTermsEndDate] = useState<string | null>(null);
  const [field, meta] = useField({ name: 'acceptedTerms', type: 'checkbox' });
  const { status } = useFormikContext();
  const isSubmitting = status === FormStatus.Submitting;
  const { transactionDetails } = useTransactionDetails();
  // TODO: as a final solution, we can NOT have a null URL passed in
  //       and we can NOT use a default, fallback URL - because we NEED
  //       the URL to match the terms type, and we have no visibility to that here
  //       This needs to be changed to display an error to the Customer
  //       if the URL is not available
  const termsAndConditionsUrl =
    transactionDetails?.terms_and_conditions_url || 'https://wpengine.com/terms-of-service/';

  useEffect(() => {
    if (transactionDetails?.terms_length && transactionDetails?.terms_length > 0) {
      const currentDate = new Date();
      const endDate = new Date(currentDate);
      const formatOptions: Intl.DateTimeFormatOptions = {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      };

      endDate.setMonth(endDate.getMonth() + transactionDetails.terms_length);
      endDate.setDate(endDate.getDate() - 1);
      setTermsStartDate(currentDate.toLocaleDateString('en-US', formatOptions));
      setTermsEndDate(endDate.toLocaleDateString('en-US', formatOptions));
    }
  }, [transactionDetails]);

  return (
    <StyledBox>
      <Box display="flex" flexDirection="row">
        <StyledCheckbox id="acceptedTerms" type="checkbox" {...field} disabled={isSubmitting} />

        <FormFieldLabel htmlFor={'acceptedTerms'}>
          <Typography variant="body2">
            I have read and agree to the{' '}
            <Link href={termsAndConditionsUrl} target="_blank" rel="noreferrer">
              Terms of Service
            </Link>{' '}
            and{' '}
            <Link href="https://wpengine.com/privacy/" target="_blank" rel="noreferrer">
              Privacy Policy
            </Link>
          </Typography>
        </FormFieldLabel>
      </Box>
      {termsStartDate && termsEndDate && (
        <span style={{ marginTop: '0.5em', marginLeft: '2em' }}>
          {`Terms Start Date: ${termsStartDate}`}
          <br />
          {`Terms End Date: ${termsEndDate}`}
        </span>
      )}
      <ValidationErrorText error={meta.touched && Boolean(meta.error)}>
        {meta.touched && meta.error}
      </ValidationErrorText>
    </StyledBox>
  );
};

export default TermsCheckbox;
