import { Box, styled } from '@material-ui/core';
import { useTransactionDetails } from 'hooks/useTransactionDetails';
import StripeContainer from 'components/StripeContainer';
import { CybersourceProvider } from 'contexts/CybersourceContext';
import StripeUpdatePaymentPage from './StripeUpdatePaymentPage';
import CybersourceUpdatePaymentPage from './CybersourceUpdatePaymentPage';
import { useBillingInfo } from 'hooks/useBillingInfo';
import { UBSFormikInitialBillingValues, FormikBillingValues } from 'models/Billing';
import { BillingInfo, UBSBillingInfo } from 'models/BillingInfo';
import CybersourceContainer from 'components/CybersourceContainer';
import StripeUBSUpdatePaymentPage from './StripeUBSUpdatePaymentPage';

export const StyledBox = styled(Box)({
  display: 'flex',
  gap: '16px',
  paddingTop: '10px',
  justifyContent: 'flex-end',
});

const UpdatePaymentInfoPage = () => {
  const { transactionDetails } = useTransactionDetails();
  const { billingInfo } = useBillingInfo();

  if (!transactionDetails || !billingInfo) {
    return null;
  }

  const initialValues: FormikBillingValues = {
    cardholderName: (billingInfo as BillingInfo).cardholder_name,
    billingAddress: (billingInfo as BillingInfo).address1,
    billingAddress2: (billingInfo as BillingInfo).address2,
    country: billingInfo.country,
    region: billingInfo.state,
    city: billingInfo.city,
    zip: billingInfo.zip,
  };

  const UBSInitialValues: UBSFormikInitialBillingValues = {
    firstName: (billingInfo as UBSBillingInfo).billing_first_name,
    lastName: (billingInfo as UBSBillingInfo).billing_last_name,
    email: (billingInfo as UBSBillingInfo).email,
    billingAddress: (billingInfo as UBSBillingInfo).address1,
    billingAddress2: (billingInfo as UBSBillingInfo).address2,
    country: billingInfo.country,
    region: billingInfo.state,
    city: billingInfo.city,
    zip: billingInfo.zip,
    accountFirstName: (billingInfo as UBSBillingInfo).first_name,
    accountLastName: (billingInfo as UBSBillingInfo).last_name,
    phoneNumber: (billingInfo as UBSBillingInfo).telephone,
    company: (billingInfo as UBSBillingInfo).company,
    accountEmail: (billingInfo as UBSBillingInfo).account_email,
  };

  if (transactionDetails.billing_system === 'netsuite' && transactionDetails?.payment_gateway === 'stripe') {
    return <StripeUBSUpdatePaymentPage portalInvoicePageUrl={transactionDetails.redirect_url} />;
  } else if (transactionDetails?.payment_gateway === 'stripe') {
    return (
      <StripeContainer>
        <StripeUpdatePaymentPage initialValues={initialValues} portalInvoicePageUrl={transactionDetails.redirect_url} />
      </StripeContainer>
    );
  } else {
    return (
      <CybersourceContainer>
        <CybersourceProvider>
          <CybersourceUpdatePaymentPage
            initialValues={UBSInitialValues}
            portalInvoicePageUrl={transactionDetails.redirect_url}
          />
        </CybersourceProvider>
      </CybersourceContainer>
    );
  }
};

export default UpdatePaymentInfoPage;
