import { useMemo } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { useStripeContext } from 'contexts/StripeContext';

interface Props {
  children: React.ReactNode;
}

const StripeContainer = ({ children }: Props) => {
  const { clientSecret, stripePromise } = useStripeContext();

  const elementsOptions = useMemo(() => {
    return {
      stripe: stripePromise,
      options: {
        clientSecret: clientSecret,
      },
    };
  }, [stripePromise, clientSecret]);

  if (!stripePromise || !clientSecret) {
    return null;
  }

  return <Elements {...elementsOptions}>{children}</Elements>;
};

export default StripeContainer;
