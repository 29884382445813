import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { mockSharedCartSummary } from 'mocks/data/mockCartSummary';
import { replaceQueryParam, setQueryParamIfNotPresent } from 'utils/devUtils';
import { useURLParams } from 'hooks/useURLParams';
import { mockStripeUBSTransactionDetailsWithIntent } from 'mocks/data/mockTransactionDetails';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Don't re-request when the window comes into focus since the data never changes.
      refetchOnWindowFocus: false,
      // Data should never be considered stale since we only want to fetch it once.
      staleTime: Infinity,
    },
  },
});

// Use mock data when in development & the useBackendAPI flag is not enabled
if (process.env.NODE_ENV === 'development' && localStorage.getItem('useBackendAPI') !== 'true') {
  // Start MSW mock server
  const { worker } = require('./mocks/browser');
  worker.start();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const salesOrder = useURLParams('sales_order');
  if (!salesOrder) {
    // Set transaction id if it doesn't exist, this will save us having to manually add it during development. Unless sales_order is present.
    setQueryParamIfNotPresent('transaction_id', mockSharedCartSummary.id);
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const redirectStatus = useURLParams('redirect_status');
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const transactionId = useURLParams('transaction_id');
  if (redirectStatus === 'succeeded' && transactionId !== mockStripeUBSTransactionDetailsWithIntent.id) {
    replaceQueryParam('transaction_id', mockStripeUBSTransactionDetailsWithIntent.id);
  }
}

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
  document.getElementById('root'),
);
