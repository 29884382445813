import { useState, useEffect } from 'react';
import ProgressStepper from 'components/ProgressStepper';
import { Formik, FormikHelpers, Form } from 'formik';
import ErrorAlert from 'components/ErrorAlert';
import Sidebar from 'components/Sidebar';
import { colors } from 'constants/colors';
import { merge } from 'lodash';
//import TermsCopyContainer from 'components/TermsCopyContainer';
import { useTransaction } from 'hooks/useTransaction';
import { useTransactionDetails } from 'hooks/useTransactionDetails';
import {
  UBSInitialValues,
  UBSSelfServeValidationSchema,
  UBSValidationSchema,
  PageContainer,
} from './SignupPaymentPage';
import { UBSFormikBillingValues, FormStatus, SetupIntentBillingPayload, StripeUBSBillingPayload } from 'models/Billing';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { useBillingInfo } from 'hooks/useBillingInfo';
import { UBSBillingInfo } from 'models/BillingInfo';
import { useBilling } from 'hooks/useBilling';
import setSegmentCookie from 'utils/CybersourceSetSegmentCookie';
import { styled, Typography } from '@material-ui/core';
import FormTextField from 'components/BillingForm/FormTextField';
import ReloadModal from 'components/BillingForm/ReloadModal';
import CountryRegionVatSelector from 'components/BillingForm/CountryRegionVatSelector';
import { useStripeContext } from 'contexts/StripeContext';

const StyledBox = styled('main')({
  display: 'flex',
  flexDirection: 'column',
  flex: '2',
  gridArea: 'form',
  backgroundColor: colors.white,
  border: `solid 1px ${colors.polar10}`,
  padding: '30px 25px',
  borderRadius: '16px',
});

const StyledForm = styled(Form)(({ theme }) => ({
  // Breakpoint which triggers on smaller screens
  [theme.breakpoints.down('sm')]: {
    marginTop: '10px',
  },
  borderRadius: '16px',
}));

const StyledAccountHeader = styled(Typography)(() => ({
  marginBottom: '40px',
  marginTop: '40px',
  borderRadius: '16px',
}));

const StyledBillingHeader = styled(Typography)(() => ({
  marginBottom: '40px',
  marginTop: '40px',
  borderRadius: '16px',
}));

const StripeUBSPaymentPage = ({ portalConfirmationPageUrl }: { portalConfirmationPageUrl: string }) => {
  const { transactionDetails } = useTransactionDetails();
  const { transaction } = useTransaction();
  const { billingInfo } = useBillingInfo();
  const stripe = useStripe();
  const elements = useElements();
  const { sendBillingInfo } = useBilling();
  const { clientSecret, stripePromise } = useStripeContext();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const isPendingSalesOrderWithoutCc = transactionDetails?.signup_code === 'pending_sales_order_without_cc';
  const isEsmSelfServe = transactionDetails?.signup_code?.includes('esm_self_serve');
  const accountDetailsAreNotPrepopulated = !(transactionDetails?.account_details_prepopulated === true);

  const [ReloadModalOpen, setReloadModalOpen] = useState(false);

  useEffect(() => {
    // Handle Stripe success redirect
    if (transactionDetails?.stripe_setup_intent) {
      const handleStripeRedirect = async () => {
        const stripe = await stripePromise;
        if (!stripe) {
          console.error('Stripe has not been loaded.');
          return;
        }

        const { setupIntent, error } = await stripe.retrieveSetupIntent(
          String(transactionDetails?.stripe_setup_intent),
        );

        if (error) {
          console.error(error);
        }

        if (setupIntent?.status === 'succeeded') {
          sendBillingInfo(
            {
              stripe_setup_intent: setupIntent?.client_secret,
              status: 'succeeded',
            } as SetupIntentBillingPayload,
            {
              onSuccess: async () => {
                setSegmentCookie(transaction, transactionDetails);
                window.location.href = `${portalConfirmationPageUrl}?email=${encodeURIComponent(
                  transactionDetails.email || '',
                )}&transaction_id=${transactionDetails.id}`;
              },
              onError: async () => {
                setErrorMessage('Unable to redirect. Please reload the page.');
              },
            },
          );
        }
      };

      handleStripeRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionDetails]);

  const handleReloadModalClose = () => {
    setReloadModalOpen(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setReloadModalOpen(true);
    }, 840000);
  }, []);

  useEffect(() => {
    if (billingInfo) {
      merge(UBSInitialValues, { email: (billingInfo as UBSBillingInfo)?.email || '' });
    }
  }, [billingInfo]);

  const createStripeIntent = async (values: UBSFormikBillingValues) => {
    if (stripe && elements) {
      const { error } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: window.location.href, // Redirect to this page after payment
          payment_method_data: {
            billing_details: {
              name: `${values.firstName} ${values.lastName}`,
              email: values.email,
              phone: values.phoneNumber,
              address: {
                line1: values.billingAddress,
                line2: values.billingAddress2,
                city: values.city,
                country: values.country,
                state: values.region,
                postal_code: values.zip,
              },
            },
          },
        },
      });
      if (error) {
        // Set error message/state?
      }
    }
  };

  const handleFormSubmit = async (values: UBSFormikBillingValues, helpers: FormikHelpers<UBSFormikBillingValues>) => {
    helpers.setStatus(FormStatus.Submitting);
    setErrorMessage('');
    const payload: StripeUBSBillingPayload = {
      address1: values.billingAddress,
      address2: values.billingAddress2,
      city: values.city,
      state: values.region,
      country: values.country,
      zip: values.zip,
      vat_id: values.vatId,
      billing_email: values.email,
      billing_first_name: values.firstName,
      billing_last_name: values.lastName,
      email: values.accountEmail || transactionDetails.email,
      first_name: values.accountFirstName,
      last_name: values.accountLastName,
      telephone: values.phoneNumber,
      company: values.company,
      status: 'pending_payment',
      stripe_setup_intent: clientSecret,
    };
    sendBillingInfo(payload, {
      onSuccess: async () => {
        createStripeIntent(values);
      },
      onError: async () => {
        // Set error message/state?
      },
    });
  };

  // Display terms & conditions if needed
  if (isPendingSalesOrderWithoutCc) {
    return (
      <PageContainer>
        <ProgressStepper />
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <ProgressStepper />
      <Formik
        initialValues={UBSInitialValues}
        validationSchema={
          isEsmSelfServe && accountDetailsAreNotPrepopulated ? UBSSelfServeValidationSchema : UBSValidationSchema
        }
        onSubmit={handleFormSubmit}
      >
        <>
          <StyledBox>
            <ErrorAlert message={errorMessage} />
            <StyledForm id="billingForm">
              {isEsmSelfServe && accountDetailsAreNotPrepopulated ? (
                <>
                  <StyledAccountHeader variant="h3">Account Details</StyledAccountHeader>
                  <FormTextField label="Account First name" name="accountFirstName" maxChars={256} />
                  <FormTextField label="Account Last name" name="accountLastName" maxChars={64} />
                  <FormTextField label="Account Email" name="accountEmail" maxChars={64} />
                  <FormTextField label="Company (optional)" name="company" maxChars={64} />
                  <FormTextField label="Phone Number (optional)" name="phoneNumber" maxChars={64} />
                </>
              ) : (
                false
              )}
              <StyledBillingHeader variant="h3">Payment Details</StyledBillingHeader>
              <PaymentElement options={{ fields: { billingDetails: 'never' } }} />
              <FormTextField label="First name" name="firstName" maxChars={256} />
              <FormTextField label="Last name" name="lastName" maxChars={64} />
              <FormTextField label="Email" name="email" maxChars={64} />
              <FormTextField label="Billing address" name="billingAddress" maxChars={256} />
              <FormTextField label="Billing address 2 (optional)" name="billingAddress2" maxChars={64} />
              <CountryRegionVatSelector />
              <FormTextField label="City" name="city" maxChars={64} />
              <FormTextField label="Zip / Postal code" name="zip" maxChars={64} />
            </StyledForm>
            <ReloadModal open={ReloadModalOpen} handleClose={handleReloadModalClose} />
          </StyledBox>
          <Sidebar />
        </>
      </Formik>
    </PageContainer>
  );
};

export default StripeUBSPaymentPage;
