import { TransactionDetails } from 'models/TransactionDetails';
import { mockCybersourceSharedCartSummary } from './mockCartSummary';

const USER_PORTAL_URL = 'https://my.dev.wpesvc.net';

export const mockFlywheelAddPaymentMethodTransactionDetails: TransactionDetails = {
  id: '7d614b88-b1b2-4e9a-857f-ecfa976da982',
  terms: 'ON_RECEIPT',
  billing_system: 'flywheel',
  payment_gateway: 'braintree',
  redirect_url: `https://app.getflywheel.com/products/hosting/tiers/3/purchase/new?billing_frequency=1`,
  cancel_url: `https://app.getflywheel.com/products/hosting/tiers/3/purchase/new?billing_frequency=1`,
  update_flow: false,
  shared_plan: false,
  signup_code: null,
  email: 'my@email.com',
  account_details_prepopulated: false,
  terms_and_conditions_url: 'https://www.example.com/terms',
  order_channel: '',
  terms_length: 24,
};

export const mockSignupTransactionDetails: TransactionDetails = {
  id: '7d614b88-b1b2-4e9a-857f-ecfa976da981',
  terms: 'ON_RECEIPT',
  billing_system: 'BAPI',
  payment_gateway: 'stripe',
  redirect_url: `${USER_PORTAL_URL}/signup/confirmation`,
  cancel_url: null,
  update_flow: false,
  shared_plan: true,
  signup_code: null,
  email: 'my@email.com',
  account_details_prepopulated: false,
  terms_and_conditions_url: 'https://www.example.com/terms',
  order_channel: '',
  terms_length: 24,
};

export const mockCybersourceSignupTransactionDetails: TransactionDetails = {
  id: '7697a6ad-eb7b-427b-8af0-cbe1dcc63ee8',
  terms: 'On Receipt',
  billing_system: 'netsuite',
  payment_gateway: 'cybersource',
  redirect_url: `${USER_PORTAL_URL}/signup/confirmation`,
  cancel_url: null,
  update_flow: false,
  shared_plan: true,
  signup_code: 'esm_signup',
  email: 'my@email.com',
  account_details_prepopulated: false,
  terms_and_conditions_url: 'https://www.example.com/terms',
  order_channel: '',
  terms_length: 24,
};

export const mockCybersourceSelfServeSignupTransactionDetails: TransactionDetails = {
  id: '5597a6ad-eb7b-427b-8af0-cbe1dcc6d851',
  terms: 'On Receipt',
  billing_system: 'netsuite',
  payment_gateway: 'cybersource',
  redirect_url: `${USER_PORTAL_URL}/signup/confirmation`,
  cancel_url: null,
  update_flow: false,
  shared_plan: true,
  signup_code: 'esm_self_serve',
  email: 'my@email.com',
  account_details_prepopulated: false,
  terms_and_conditions_url: 'https://www.example.com/terms',
  order_channel: '',
  terms_length: 24,
};

export const mockNonSharedTransactionDetails: TransactionDetails = {
  id: 'e23052d0-efd6-4e32-ad44-973cb994ba97',
  terms: 'ON_RECEIPT',
  billing_system: 'BAPI',
  payment_gateway: 'stripe',
  redirect_url: `${USER_PORTAL_URL}/signup/confirmation`,
  cancel_url: null,
  update_flow: false,
  shared_plan: false,
  signup_code: null,
  email: 'my@email.com',
  account_details_prepopulated: false,
  terms_and_conditions_url: 'https://www.example.com/terms',
  order_channel: '',
  terms_length: 24,
};

export const mockGenProTransactionDetails: TransactionDetails = {
  id: '3c5fba39-e5cd-4ca4-ad0b-f7b01135a81d',
  terms: 'ON_RECEIPT',
  billing_system: 'BAPI',
  payment_gateway: 'stripe',
  redirect_url: `${USER_PORTAL_URL}/signup?plan=genesis-pro-only&transaction_id=3c5fba39-e5cd-4ca4-ad0b-f7b01135a81d`,
  cancel_url: null,
  update_flow: false,
  shared_plan: false,
  signup_code: null,
  email: 'my@email.com',
  account_details_prepopulated: false,
  terms_and_conditions_url: 'https://www.example.com/terms',
  order_channel: '',
  terms_length: null,
};

export const mockUpdateTransactionDetails: TransactionDetails = {
  id: '13g9ah21-1d32-12eZ-r323-ud7a6RNs6teV',
  terms: 'ON_RECEIPT',
  billing_system: 'BAPI',
  payment_gateway: 'stripe',
  redirect_url: `${USER_PORTAL_URL}/my_account`,
  cancel_url: null,
  update_flow: true,
  shared_plan: true,
  signup_code: null,
  email: 'my@email.com',
  account_details_prepopulated: false,
  terms_and_conditions_url: 'https://www.example.com/terms',
  order_channel: '',
  terms_length: null,
};

export const mockCybersourceUpdateTransactionDetails: TransactionDetails = {
  id: 'af96ebd5-d188-46a2-9acf-4081e486b4f9',
  terms: 'On Receipt',
  billing_system: 'netsuite',
  payment_gateway: 'cybersource',
  redirect_url: `${USER_PORTAL_URL}/my_account`,
  cancel_url: null,
  update_flow: true,
  shared_plan: true,
  signup_code: null,
  email: 'my@email.com',
  account_details_prepopulated: false,
  terms_and_conditions_url: 'https://www.example.com/terms',
  order_channel: '',
  terms_length: null,
};

export const mockTermsOnlyTransactionDetails: TransactionDetails = {
  id: 'termebd5-d188-46a2-9acf-4081e4868394',
  terms: 'net 20',
  billing_system: 'netsuite',
  payment_gateway: 'cybersource',
  redirect_url: `${USER_PORTAL_URL}`,
  cancel_url: null,
  update_flow: false,
  shared_plan: true,
  signup_code: 'pending_sales_order_without_cc',
  email: 'my@email.com',
  account_details_prepopulated: false,
  terms_and_conditions_url: 'https://www.example.com/terms',
  order_channel: 'sales_assisted',
  terms_length: 24,
};

export const mockStripeUBSTransactionDetails: TransactionDetails = {
  id: 'stripes5-d188-46a2-9acf-4081e9483029',
  terms: 'On Receipt',
  billing_system: 'netsuite',
  payment_gateway: 'stripe',
  redirect_url: `${USER_PORTAL_URL}`,
  cancel_url: null,
  update_flow: false,
  shared_plan: true,
  signup_code: 'esm_self_serve',
  email: 'my@email.com',
  account_details_prepopulated: false,
  terms_and_conditions_url: 'https://www.example.com/terms',
  order_channel: 'self-serve',
  terms_length: 24,
};

export const mockStripeUBSTransactionDetailsWithIntent: TransactionDetails = {
  id: 'stripes5-d188-46a2-9acf-4081e9483030',
  terms: 'On Receipt',
  billing_system: 'netsuite',
  payment_gateway: 'stripe',
  redirect_url: `${USER_PORTAL_URL}`,
  cancel_url: null,
  update_flow: false,
  shared_plan: true,
  signup_code: 'esm_self_serve',
  email: 'my@email.com',
  account_details_prepopulated: false,
  terms_and_conditions_url: 'https://www.example.com/terms',
  order_channel: 'self-serve',
  stripe_setup_intent: String(process.env.REACT_APP_STRIPE_CLIENT_SECRET),
  terms_length: 24,
};

export const mockStripeUBSUpdateTransactionDetails: TransactionDetails = {
  id: 'stripes5-d188-46a2-9acf-4081e9update',
  terms: 'On Receipt',
  billing_system: 'netsuite',
  payment_gateway: 'stripe',
  redirect_url: `${USER_PORTAL_URL}`,
  cancel_url: null,
  update_flow: true,
  shared_plan: true,
  signup_code: 'esm_self_serve',
  email: 'my@email.com',
  account_details_prepopulated: false,
  terms_and_conditions_url: 'https://www.example.com/terms',
  order_channel: 'self-serve',
  terms_length: 24,
};

export const mockSalesOrderTransactionId: string = mockCybersourceSharedCartSummary.id;
