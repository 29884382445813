import { useMutation, useQuery } from 'react-query';
import FetchAPI from 'services/FetchAPI';
import { CYBERSOURCE_KEY_URL, CYBERSOURCE_PAYMENTS_URL } from 'constants/urls';
import {
  UBSBillingPayload,
  TermsOnlyPayload,
  CybersourcePayerAuthSetup,
  CybersourcePayerAuthEnrollment,
  CybersourcePayerAuthValidation,
  CybersourceLogging,
} from 'models/Billing';
import { useURLParams } from './useURLParams';

export const useCybersource = () => {
  const transactionId = useURLParams('transaction_id');
  const salesOrder = useURLParams('sales_order');

  // Fetches cybersourceKey
  const getCybersourceKey = useQuery(
    'cybersourceKey',
    () => {
      return FetchAPI.get(`${CYBERSOURCE_KEY_URL}?transaction_id=${transactionId}`);
    },
    { enabled: !salesOrder },
  );

  const { mutate: sendBillingInfo } = useMutation((payload: UBSBillingPayload | TermsOnlyPayload | undefined) =>
    FetchAPI.post(`${CYBERSOURCE_PAYMENTS_URL}?transaction_id=${transactionId}`, payload, 120000),
  );

  // Setup Payer Auth
  const { mutate: setupPayerAuthStep1 } = useMutation((payload: CybersourcePayerAuthSetup) => {
    const response = FetchAPI.post(`/risk/v1/authentication-setups`, payload, 120000);
    return response;
  });

  // Payer Auth Enroll
  const { mutate: enrollmentCheckStep3 } = useMutation((payload: CybersourcePayerAuthEnrollment) => {
    const response = FetchAPI.post(`/risk/v1/authentications`, payload, 120000);
    return response;
  });

  // Payer Auth Enroll
  const { mutate: validationCheckStep5 } = useMutation((payload: CybersourcePayerAuthValidation) => {
    const response = FetchAPI.post(`/risk/v1/authentication-results`, payload, 120000);
    return response;
  });

  // Logging
  const { mutate: logging } = useMutation((payload: CybersourceLogging) => {
    const response = FetchAPI.post(`/logging`, payload, 120000);
    return response;
  });

  const submitPayerAuthIframe = () => {
    var cardinalCollectionForm: HTMLFormElement = document.querySelector(
      '#cardinal_collection_form',
    ) as HTMLFormElement;
    if (cardinalCollectionForm) {
      // form exists click button
      cardinalCollectionForm.submit();
    }
  };

  return {
    getCybersourceKey,
    sendBillingInfo,
    setupPayerAuthStep1,
    submitPayerAuthIframe,
    enrollmentCheckStep3,
    validationCheckStep5,
    logging,
  };
};
